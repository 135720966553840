import _ from '@/utils/lodash'
import { likeRegExp } from '@/offline/database'

export default {
  _selectParteTrabajoMatsist (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .where(tables.parte_trabajo_matsist.idparte_trabajo.eq(idparteTrabajo))
      .exec()
  },
  _selectMaterialSistemaBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.material_sistema)
      .innerJoin(
        tables.articulo,
        tables.material_sistema.idarticulo.eq(tables.articulo.idarticulo)
      )
      .innerJoin(
        tables.subfamilia,
        tables.articulo.idsubfamilia.eq(tables.subfamilia.idsubfamilia)
      )
      .innerJoin(
        tables.familia,
        tables.subfamilia.idfamilia.eq(tables.familia.idfamilia)
      )
      .innerJoin(
        tables.subsis,
        tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
      )
      .innerJoin(
        tables.tsubsis,
        tables.subsis.idtsubsis.eq(tables.tsubsis.idtsubsis)
      )
  },
  selectMaterialSistemaMetadata (sorter) {
    const metadata = {
      sorter_desc: [
        { label: 'Nº de orden', name: 'n_orden', field: 'material_sistema.n_orden' },
        { label: 'Nº de serie', name: 'nserie', field: 'material_sistema.nserie' },
        { label: 'Nº de elemento', name: 'nelemento', field: 'material_sistema.nelemento' },
        { label: 'Nº de etiqueta', name: 'netiqueta', field: 'material_sistema.netiqueta' },
        { label: 'Código', name: 'codigo', field: 'material_sistema.codigo' },
        { label: 'Descripción', name: 'descripcion', field: 'material_sistema.descripcion' },
      ],
    }
    if (!_.isEmpty(sorter)) {
      metadata.sorter = sorter
    } else {
      metadata.sorter = [
        { field: 'n_orden', asc: true },
      ]
    }
    return metadata
  },
  async selectMaterialSistema (Vue, filter, search, sorter, page, idsistema, idparteTrabajoExclude, idsubsis) {
    const tables = Vue.$offline.db.tables
    let metadata = this.selectMaterialSistemaMetadata(sorter)
    let where = []
    let whereSearch = []
    let whereFilter = []
    let whereParteTrabajoExclude = []
    let whereSubsis = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.material_sistema.nserie.match(reQ),
          tables.material_sistema.nelemento.match(reQ),
          tables.material_sistema.netiqueta.match(reQ),
          tables.material_sistema.descripcion.match(reQ)
        )
      )
    }
    if (filter.codigo.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.material_sistema.codigo.match(likeRegExp(filter.codigo.value))
        )
      )
    }
    if (filter.descripcion.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.material_sistema.descripcion.match(likeRegExp(filter.descripcion.value))
        )
      )
    }
    if (filter.idsubsis.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.material_sistema.idsubsis.in(filter.idsubsis.value),
        )
      )
    }
    if (idparteTrabajoExclude) {
      const parteTrabajoMatsist = await this._selectParteTrabajoMatsist(Vue, idparteTrabajoExclude)
      const idsMaterialSistema = _.map(parteTrabajoMatsist, 'material_sistema.idmaterial_sistema')
      // TODO: https://github.com/google/lovefield/issues/274
      idsMaterialSistema.push(-1)
      whereParteTrabajoExclude.push(
        Vue.$offline.db.op.not(
          tables.material_sistema.idmaterial_sistema.in(idsMaterialSistema)
        ),
      )
    }
    if (idsubsis) {
      whereSubsis.push(
        tables.material_sistema.idsubsis.eq(idsubsis)
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.material_sistema.estado.gt(0),
        tables.subsis.idsistema.eq(idsistema),
        ...whereParteTrabajoExclude,
        ...whereSubsis,
        ...whereSearch,
        ...whereFilter
      )
    )
    let query = this._selectMaterialSistemaBase(Vue)
      .where(...where)
      .limit(Vue.$offline.db.ITEMS_PER_PAGE)
      .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
    query = Vue.$offline.db.applySorter(query, metadata)
    const rows = await query.exec()
    return [rows, metadata]
  },
  selectMaterialSistemaRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    return this._selectMaterialSistemaBase(Vue)
      .where(
        Vue.$offline.db.op.and(
          tables.material_sistema.estado.gt(0),
          tables.material_sistema.fdesinstalacion.isNull(),
          tables.material_sistema.idmaterial_sistema.in(pks),
        )
      )
      .exec()
  }
}
